import { ref, onMounted, onUnmounted } from 'vue';
import astraPlaceHolder from '@/assets/astra-placeholder.png'; // Importa el placeholder


// eslint-disable-next-line no-unused-vars
const stationId = 1; // Como una ref
// eslint-disable-next-line no-unused-vars
const nowPlaying = ref();
const posts = ref([]);
const spotyClientI = process.env.VUE_APP_SPOTY_CLIENT_I;
const clientSpotyS = process.env.VUE_APP_SPOTY_CLIENT_S;
// eslint-disable-next-line no-unused-vars
const lastFmApiK = process.env.VUE_APP_LAST_FM_API_K;
const azuracastApik = process.env.VUE_APP_AZURACAST_K



async function fetchNowPlaying(stationId) {
    //console.log('the id', stationId)
    try {
        const response = await fetch(`https://listen.astra.fm/api/nowplaying/${stationId}`);
        const data = await response.json();
        let nowPlaying = data.now_playing;
        let nextPlaying = data.playing_next; // Agrega esta línea para obtener la próxima canción
        //console.log(nowPlaying.song.custom_fields);
        // Llamada a Spotify para obtener información adicional del artista
        fetchSpotifyArtistInfo(nowPlaying.song.artist);
        // Llamada a Spotify para obtener información del álbum, incluido el año de lanzamiento
        fetchLastFmArtistInfo(nowPlaying.song.artist);
        // La API devuelve información del álbum dentro de now_playing
        const albumYear = nowPlaying.song.custom_fields['album_year'];
        const albumArt = nowPlaying.song.art; // Extrae la URL de la imagen del álbum
        const albumName = nowPlaying.song.album; // Asumiendo que este es el nombre del álbum.

        // Extrae la información relevante de la próxima canción si está disponible
        const nextAlbumArt = nextPlaying?.song.art; // URL de la imagen del álbum de la próxima canción
        const nextAlbumName = nextPlaying?.song.album; // Nombre del álbum de la próxima canción
        const nextAlbumYear = nextPlaying?.song.custom_fields['album_year'];// Año del álbum de la próxima canción

        // Retorna el objeto nowPlaying extendido con la información del álbum y el año de lanzamiento
        return { 
            nowPlaying: { ...nowPlaying, albumArt, albumName, albumYear },
            nextPlaying: nextPlaying ? { ...nextPlaying, nextAlbumArt, nextAlbumName, nextAlbumYear } : null 
        };    
    } catch (error) {
        console.error("Error fetching now playing:", error);
        return null; // Maneja el error devolviendo null o un objeto predeterminado
    }
    
}






// En api.js

async function fetchLastTenSongs(stationId = 1) {
    const historyLimit = 10; // Número de canciones recientes que quieres obtener
    try {
        const response = await fetch(`https://listen.astra.fm/api/station/${stationId}/history?limit=${historyLimit}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'X-API-Key': azuracastApik // reemplaza con tu clave API real
            }
        });

        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        let data = await response.json(); // Convierte el cuerpo de la respuesta a JSON
        data = data.filter(song => !(song.playlist === "General Jingles" || song.song.album === "presentaciones" || song.song.album === "shorts" || song.playlist === "Off Jingles" || song.playlist === "Urbano shorts" || song.playlist === "Pulsar Shorts"));

        // Eliminar duplicados basados en el id único de cada canción
        let uniqueSongs = [];
        const songIds = new Set();
        for (const song of data) {
            if (!songIds.has(song.song.id)) {
                uniqueSongs.push(song);
                songIds.add(song.song.id);
            }
        }

        const songsWithImage = uniqueSongs.map(song => ({
            ...song,
            imageUrl: song.song.art || 'URL_de_imagen_por_defecto', 
            albumYear: song.song.custom_fields ? song.song.custom_fields.album_year : undefined,
            playedAtReadable: new Date(song.played_at * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        })).slice(0, 10);

        return songsWithImage;
    } catch (error) {
        console.error('API Error:', error);
        return []; // Retorna un arreglo vacío o maneja el error como prefieras
    }
}


// Lista de alias permitidos
const allowedPlaylists = [
    "El Resplandor", "Morning Circus", "Twilight", 
    "Skynet", "Nightfall", "Ruido Blanco", "Urbano", "Pulsar"
  ];
  
  // Mapeo de alias para playlists
  const playlistAliasMap = {
      "El resplandor I": "El Resplandor",
      "El resplandor II": "El Resplandor",
      "Morning Circus I": "Morning Circus",
      "Morning Circus II": "Morning Circus",
      "Twilight I": "Twilight", 
      "Twilight II": "Twilight",
      "Skynet I": "Skynet",
      "Skynet II": "Skynet",
      "Nightfall I": "Nightfall",
      "Nightfall II": "Nightfall",
      "Ruido blanco I": "Ruido Blanco",
      "Ruido blanco II": "Ruido Blanco",
      "Urbano Jazz II": "Urbano",
      "Urbano Breaks I": "Urbano",
      "Urbano Breaks II": "Urbano",
      "Urbano Jazz I": "Urbano",
      "Pulsar - Experimental dance I": "Pulsar",
      "Pulsar - Experimental dance II": "Pulsar",
      "Briandance I": "Pulsar",
      "Briandance II": "Pulsar",
      "Glitch and experimental I": "Pulsar",
      "Glitch and experimental II": "Pulsar"
  };
 
  
  async function fetchAlbumsByPlaylist(stationId) {
      const historyLimit = 40; // Aumenta el límite para asegurar una recopilación exhaustiva antes de filtrar
      try {
          const response = await fetch(`https://listen.astra.fm/api/station/${stationId}/history?limit=${historyLimit}`, {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'X-API-Key': azuracastApik // reemplaza con tu clave API real
              }
          });
  
          if (!response.ok) {
              throw new Error(`API call failed with status: ${response.status}`);
          }
  
          let data = await response.json();
          //console.log("Datos brutos de la API:", data);  // Log de los datos brutos

          // Filtra canciones no deseadas y las que no están en los alias permitidos
          data = data.filter(song => {
              const alias = playlistAliasMap[song.playlist];
              return alias && allowedPlaylists.includes(alias) &&
                     !(song.playlist.includes("Jingles") || song.song.album === "presentaciones" || song.playlist.includes("Off Jingles") || song.playlist.includes("Urbano shorts") || song.playlist.includes("Pulsar Shorts"));
          });
          //console.log("Datos filtrados antes del mapeo:", data.map(song => song.playlist));  // Mostrar solo los nombres de las playlists recibidas

  
          // Agrupar canciones por playlist con alias
          const albumsByPlaylist = data.reduce((acc, song) => {
              const playlist = playlistAliasMap[song.playlist] || song.playlist; // Usa el alias si está disponible
              if (!acc[playlist]) acc[playlist] = [];
              const albumExists = acc[playlist].some(album => album.album === song.song.album);
              if (!albumExists) {
                  acc[playlist].push({...song.song, playedAt: song.played_at});
              }
              return acc;
          }, {});
  
          // Ordenar por playedAt y limitar a los últimos 20 álbumes para cada playlist
          for (let playlist in albumsByPlaylist) {
              albumsByPlaylist[playlist] = albumsByPlaylist[playlist]
                  .sort((a, b) => b.playedAt - a.playedAt) // Orden descendente por fecha de reproducción
                  .slice(0, 40);
          }
  
          return albumsByPlaylist;
      } catch (error) {
          console.error('API Error:', error);
          return {}; // Retorna un objeto vacío o maneja el error como prefieras
      }

      
  }


async function fetchNewSongs(stationId = 1) {
    const fileLimit = 10; // Número de archivos que quieres obtener
    
    try {
        const response = await fetch(`https://listen.astra.fm/api/station/${stationId}/files?limit=${fileLimit}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'X-API-Key': azuracastApik// reemplaza con tu clave API real
            }
        });

        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        

        const data = await response.json(); // Convierte el cuerpo de la respuesta a JSON
        //imageUrl: song.song.art || 'URL_de_imagen_por_defecto';
        //data = data.filter(song => !(song.playlist === "General Jingles" || song.playlist === "Off Jingles"));


        // Considerando que estás interesado en el campo 'mtime' para la fecha de modificación:
        const sortedByRecentAddition = data.sort((a, b) => b.mtime - a.mtime).slice(0, 10);
        return sortedByRecentAddition; // Devuelve los archivos con la fecha de modificación legible
    } catch (error) {
        console.error('API Error:', error);
        return []; // Retorna un arreglo vacío o maneja el error como prefieras
    }
}

async function fetchSpotifyArtistInfo(artistName) {
    try {
        const clientId = spotyClientI;
        const clientSecret = clientSpotyS;

        const tokenResponse = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret)
            },
            body: 'grant_type=client_credentials'
        });

        if (!tokenResponse.ok) {
            throw new Error(`Error al obtener token: ${tokenResponse.statusText}`);
        }

        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;

        const searchResponse = await fetch(`https://api.spotify.com/v1/search?q=${encodeURIComponent(artistName)}&type=artist`, {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        if (!searchResponse.ok) {
            throw new Error(`Error en la búsqueda de Spotify: ${searchResponse.statusText}`);
        }

        const searchData = await searchResponse.json();
        const normalizedArtistName = artistName.normalize('NFD').replace(/[̀-ͯ]/g, "").toLowerCase();

        const exactMatchArtist = searchData.artists.items.find(artist =>
            artist.name.normalize('NFD').replace(/[̀-ͯ]/g, "").toLowerCase() === normalizedArtistName
        );

        const spotifySupportLink = exactMatchArtist?.external_urls?.spotify || "";

        // Obtener imagen de WordPress
        const wpImage = await fetchWordPressImage(artistName);
        if (wpImage) {
            return {
                image: wpImage.url,
                supportLink: wpImage.source, // Aquí se mantiene la fuente de WordPress
                sourceName: wpImage.name,
                spotifyArtistProfile: spotifySupportLink
            };
        }

        // Si la imagen proviene de Spotify, el link debe ser al perfil de Spotify
        return {
            image: exactMatchArtist?.images[0]?.url || astraPlaceHolder,
            supportLink: spotifySupportLink, // Se usa el link de Spotify
            sourceName: "Spotify",
            spotifyArtistProfile: spotifySupportLink
        };
    } catch (error) {
        console.error('fetchSpotifyArtistInfo failed', error);
        return {};
    }
}
/*async function fetchMusicBrainzArtistImage(artistName) {
    try {
        const artistSearchRes = await fetch(`https://musicbrainz.org/ws/2/artist/?query=${encodeURIComponent(artistName)}&fmt=json`);
        const artistSearchData = await artistSearchRes.json();

        const exactArtist = artistSearchData.artists.find(artist =>
            artist.name.toLowerCase() === artistName.toLowerCase()
        );

        if (!exactArtist) throw new Error("Artista no encontrado en MusicBrainz");

        const artistId = exactArtist.id;

        const releaseRes = await fetch(`https://musicbrainz.org/ws/2/release?artist=${artistId}&fmt=json&limit=5`);
        const releaseData = await releaseRes.json();

        const release = releaseData.releases.find(r => r['id']);
        if (!release) throw new Error("No hay releases disponibles");

        const releaseId = release.id;

        const coverRes = await fetch(`https://coverartarchive.org/release/${releaseId}`);
        const coverData = await coverRes.json();

        const coverImage = coverData.images?.[0]?.image;
        if (!coverImage) throw new Error("No se encontró imagen en Cover Art Archive");

        return {
            image: coverImage,
            supportLink: `https://musicbrainz.org/artist/${artistId}`,
            sourceName: "Cover Art Archive / MusicBrainz"
        };
    } catch (error) {
        console.error("fetchMusicBrainzArtistImage failed:", error.message);
        return {
            image: astraPlaceHolder,
            supportLink: "",
            sourceName: "Sin imagen disponible"
        };
    }
}

async function fetchSpotifyArtistInfo(artistName) {
    try {
        const artistList = artistName.split(',').map(name => name.trim());

        for (const name of artistList) {
            // 1. WordPress
            const wpImage = await fetchWordPressImage(name);
            if (wpImage) {
                return {
                    image: wpImage.url,
                    supportLink: wpImage.source,
                    sourceName: wpImage.name,
                    spotifyArtistProfile: null
                };
            }

            // 2. MusicBrainz
            const mbImage = await fetchMusicBrainzArtistImage(name);
            const imageFromMB = mbImage?.image && mbImage.image !== astraPlaceHolder;

            if (imageFromMB) {
                return {
                    image: mbImage.image,
                    supportLink: mbImage.supportLink,
                    sourceName: mbImage.sourceName,
                    spotifyArtistProfile: null
                };
            }
        }

        // 3. Spotify (último recurso, intentamos con el primer nombre de la lista)
        const primaryName = artistList[0];
        const clientId = spotyClientI;
        const clientSecret = clientSpotyS;

        const tokenResponse = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret)
            },
            body: 'grant_type=client_credentials'
        });

        if (!tokenResponse.ok) {
            throw new Error(`Error al obtener token: ${tokenResponse.statusText}`);
        }

        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;

        const searchResponse = await fetch(`https://api.spotify.com/v1/search?q=${encodeURIComponent(primaryName)}&type=artist`, {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        if (!searchResponse.ok) {
            throw new Error(`Error en la búsqueda de Spotify: ${searchResponse.statusText}`);
        }

        const searchData = await searchResponse.json();
        const normalizedArtistName = primaryName.normalize('NFD').replace(/[̀-ͯ]/g, "").toLowerCase();

        const exactMatchArtist = searchData.artists.items.find(artist =>
            artist.name.normalize('NFD').replace(/[̀-ͯ]/g, "").toLowerCase() === normalizedArtistName
        );

        const spotifySupportLink = exactMatchArtist?.external_urls?.spotify || "";

        return {
            image: exactMatchArtist?.images[0]?.url || astraPlaceHolder,
            supportLink: spotifySupportLink,
            sourceName: "Spotify",
            spotifyArtistProfile: spotifySupportLink
        };
    } catch (error) {
        console.error('fetchSpotifyArtistInfo failed', error);
        return {
            image: astraPlaceHolder,
            supportLink: "",
            sourceName: "Error",
            spotifyArtistProfile: null
        };
    }
}
*/

// Modificación en la función fetchWordPressImage para incluir los campos personalizados
async function fetchWordPressImage(artistName) {
    try {
        const post = await fetchPostByArtistName(artistName);
        if (post && post.featured_image_url) {
            return {
                url: post.featured_image_url, // URL de la imagen destacada
                source: post.acf?.fuente_imagen_home || '', // URL de la fuente
                name: post.acf?.nombre_imagen_home || '' // Nombre de la fuente
            };
        }
        return null; // No hay imagen en WordPress
    } catch (error) {
        console.error('Error fetching image from WordPress:', error);
        return null;
    }
}

// Función auxiliar para buscar la imagen destacada del post en WordPress
/*async function fetchWordPressImage(artistName) {
    try {
        const post = await fetchPostByArtistName(artistName);
        if (post && post.featured_image_url) {
            return post.featured_image_url;  // Devolver la URL de la imagen destacada si está disponible
        }
        return null;  // No hay imagen en WordPress
    } catch (error) {
        console.error('Error fetching image from WordPress:', error);
        return null;
    }
}*/



// En api.js
async function fetchLastFmArtistInfo(artistName, lastFmApiKey) {
    if (!lastFmApiKey) {
        //console.error("La clave API de LastFM no está definida.");
        return "La clave API no está disponible.";
    }
    const lastFmUrl = `https://ws.audioscrobbler.com/2.0/?method=artist.getinfo&artist=${encodeURIComponent(artistName)}&api_key=${lastFmApiKey}&format=json`;

    try {
        const response = await fetch(lastFmUrl);
        const data = await response.json();
        if (data.artist && data.artist.bio && data.artist.bio.summary) {
            let bio = data.artist.bio.summary;

            // Frases que indican múltiples artistas
            const phrasesToCheck = [
                "There are multiple artists called",
                "There is more than one artist with this name",
                "There is more than one band with the name",
                "There are numerous artists with this name",
                "There are",
                "there are",
                "2)",
            ];

            // Función para verificar si alguna frase está en la biografía
            const containsAnyPhrase = (bio, phrases) => phrases.some(phrase => bio.includes(phrase));

            // Eliminar el enlace "Read more on Last.fm"
            bio = bio.replace(/<a href="[^"]+">Read more on Last\.fm<\/a>/, '').trim();

            if (containsAnyPhrase(bio, phrasesToCheck)) {
                // Manejar casos con múltiples artistas
                // Podrías intentar extraer la información relevante o simplificar la respuesta
                return;
            } else if (bio.length > 0) {
                // Devuelve la biografía filtrada si pasa las comprobaciones
                return bio;
            }
        }
        return;
    } catch (error) {
        //console.error("Error fetching LastFM artist info:", error);
        return;
    }
}



const BASE_URL = `https://astra.fm/astraless/wp-json/wp/v2`;

async function fetchPostsByCategorySlug(categoryId, perPage) {
    // Incorpora el parámetro `per_page` en la URL
    const cacheBuster = Date.now();
    const url = categoryId ? `${BASE_URL}/posts?_fields=id,title,content,acf&categories=${categoryId}&per_page=${perPage}&cacheBust=${cacheBuster}` : `${BASE_URL}/posts?_fields=id,title,content,acf&per_page=${perPage}&cacheBust=${cacheBuster}`;
    //console.log(`Cargando posts de la categoría ID: ${url}`);

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
    const data = await response.json();
    return data;
    
}

async function fetchPostByArtistName(artistName) {
    // Agregamos 'featured_media' para obtener el ID de la imagen destacada
    const cacheBuster = Date.now();
    const url = `${BASE_URL}/posts?_fields=id,title,content,acf,featured_media&search=${encodeURIComponent(artistName)}&per_page=1&cacheBust=${cacheBuster}`;

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const posts = await response.json();

    // Verificamos si hay posts en la respuesta
    if (posts.length === 0) {
      return null;
    }

    const post = posts[0];

    // Si el post tiene una imagen destacada (featured_media), hacemos una segunda solicitud para obtener su URL
    let featuredImageUrl = null;
    if (post.featured_media) {
      const mediaUrl = `${BASE_URL}/media/${post.featured_media}?_fields=source_url`;
      const mediaResponse = await fetch(mediaUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (mediaResponse.ok) {
        const mediaData = await mediaResponse.json();
        featuredImageUrl = mediaData.source_url; // Obtenemos la URL de la imagen destacada
      }
    }

    // Devolvemos el post con toda la información original, incluyendo los campos que se pidieron en `_fields`
    return {
      id: post.id,                                 // ID del post
      title: post.title?.rendered || '',           // Título del post (renderizado)
      content: post.content?.rendered || '',       // Contenido del post (renderizado)
      acf: post.acf || {},                         // Campos personalizados (Advanced Custom Fields)
      featured_media: post.featured_media || null, // ID de la imagen destacada (si existe)
      featured_image_url: featuredImageUrl,        // URL de la imagen destacada (si existe)
      // Puedes agregar más campos si lo necesitas
    };
}


// Define la función que carga los posts
async function loadPosts(categoryId, perPage) {
    //console.log(`Cargando posts de la categoría ID en load posts: ${categoryId}`);

    try {
      const data = await fetchPostsByCategorySlug(categoryId, perPage);
      posts.value = data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
}
  
  // Utiliza onMounted para iniciar el intervalo cuando el componente se monta
onMounted(() => {
    // Carga inicial de los posts
    loadPosts('8', 43);

    // Establece el intervalo para actualizar los posts cada 5 minutos (300000 milisegundos)
    const intervalId = setInterval(() => {
        loadPosts('8', 43);
    }, 300000);

    // Limpia el intervalo cuando el componente se desmonte para evitar efectos secundarios no deseados
    onUnmounted(() => {
        clearInterval(intervalId);
    });
});


 //llamadas a las apis para el player y la sección radio
  


  
  
export { fetchPostsByCategorySlug, fetchWordPressImage, fetchNowPlaying, fetchSpotifyArtistInfo, fetchLastFmArtistInfo, fetchLastTenSongs, fetchNewSongs, fetchAlbumsByPlaylist, fetchPostByArtistName };